import React from 'react'
import { useState } from "react"
import "../styles/Header.css"
import { Link } from "react-router-dom";
import { FaGithub, FaMedium, FaLinkedin } from "react-icons/fa";

function Header() {
  const [isNavExpanded, setIsNavExpanded] = useState(false)

  return (
    <nav className="navigation">

      <button
        className="hamburger"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded)
        }}
      >

        <div className="hamburger-line" />
        <div className="hamburger-line" />
        <div className="hamburger-line" />

      </button>
      <div
        className={
          isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
        }
      >
        <ul>
          <li>
            <Link className="home-headingw" to="/">Kaan 🧑🏻‍💻</Link>
          </li>
          <li>
            <div>

              <a className="home-headingw" href="https://github.com/knhakan" title='Github'><FaGithub size={'20px'} /></a>
            </div>
          </li>
          <li>
            <div>
              <a className="home-headingw" href="https://medium.com/@kaanonsoftware" title='Medium'><FaMedium size={'20px'} /></a>
            </div>
          </li>
          <li>
            <div>
              <a className="home-headingw" href="https://linkedin.com/in/kaan-hakan/" title='Linkedin'><FaLinkedin size={'20px'} /></a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default Header