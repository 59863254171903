import React from 'react'
import Header from '../components/Header.jsx';
import Container from '@mui/material/Container';

export const Posts = () => {
    return (
        <div className="about-me">
            <Header />
            <Container maxWidth="lg">
                
            </Container>
        </div>
    )
}

export default Posts
