import React from 'react'
import '../styles/Aboutme.css'
import Header from '../components/Header.jsx';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

export const Aboutme = () => {
  return (
    <div >
      <Header />
      <Container maxWidth="md" className="about-me-container">
        <Box sx={{ bgcolor: '#fff', height: '100vh' }}>
          <h3>About me</h3>
            <div>
              I am a software developer working in Berlin, I am partly a digital nomad. I am passionate about building software that is easy to use and maintain.
            </div>

          <h3>My skills</h3>
          <div style={{ borderTop: "2px solid #aaa ", marginLeft: 20, marginRight: 20, marginTop: -20, marginBottom: 10 }}></div>
            <span>
            <b>Languages</b>: Java, Kotlin, Javascript, React, Go, Python 
            </span>
            <br/>
            <span>
            <b>Concepts & Tools</b>: Databases (SQL & NoSQL), Microservices, REST, GraphQL, CI/CD, Docker, Kubernetes, 
            AWS, GCP, Git, Agile, Scrum, Job management systems, push notifications and more.
            </span>
          <h3>Interests</h3>
          <div style={{ borderTop: "2px solid #aaa ", marginLeft: 20, marginRight: 20, marginTop: -20, marginBottom: 10 }}></div>
            Travelling, playing tennis, hiking and cycling are my biggest passions. I occasionally write about my experiences on my blog.
            I edit videos and publish them on my YouTube channel. 

        </Box>
      </Container>
    </div>
  )
}

export default Aboutme
