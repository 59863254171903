import React from 'react'
import Header from '../components/Header.jsx';
import Container from '@mui/material/Container';
import '../styles/Home.css'
import { FaGithub, FaMedium, FaLinkedin } from "react-icons/fa";
import profilePic from '../images/profilepic.png';


export const Home = () => {
    return (
        <div className="dot-background">
            <Header />
            <Container maxWidth="md">
                <div style={{ display: 'flex', justifyContent: 'center', alignItems:'center' }}>
                    <div style={{ marginTop: '40px', marginBottom: '40px', marginRight: '50px', display: 'flex', justifyContent: 'center', fontWeight: 'bold' }}>Hi, I am Kaan! 👋 </div>
                    <img src={profilePic} alt="Kaan Hakan" style={{ borderRadius: '50%', width: '150px', height: '165px', marginTop: '40px' }} />
                </div>
                <p className="small-text" style={{ lineHeight: '1.8' }}>
                    I'm a software developer who creates open-source projects and writes about code, design, and life. I like tennis, travelling and hiking.
                </p>
                <div style={{ lineHeight: '1.8' }}>
                    I am passionate about building software that is easy to use and maintain.
                </div>
                <p className="small-text" style={{ lineHeight: '1.8' }}>
                    Outside of programming, I enjoy doing photography and traveling. Right now I live in Berlin. If you are around, feel free to reach me out, we could have some coffee or work together.
                </p>

                <h3>My skills</h3>
                <div style={{ borderTop: "2px solid #aaa ", marginLeft: 20, marginRight: 20, marginTop: -20, marginBottom: 10 }}></div>
                <span style={{ lineHeight: '1.8' }}>
                    <b>Languages</b>: Java, Kotlin, Javascript, React, Go, Python
                </span>
                <br />
                <span style={{ lineHeight: '1.8' }}>
                    <b>Architectural & Design Concepts</b>: Microservices, Databases (SQL & NoSQL), CI/CD, Docker, Kubernetes, AWS, Job management systems, push notifications.
                </span>
                <br />

                <h3>Interests</h3>
                <div style={{ borderTop: "2px solid #aaa ", marginLeft: 20, marginRight: 20, marginTop: -20, marginBottom: 10 }}></div>
                <div style={{ lineHeight: '1.8' }}>
                    Travelling, playing tennis, hiking and cycling are my biggest passions. I occasionally write about my experiences on my blog.
                    I edit videos and publish them on my YouTube channel.
                </div>

                <div style={{ lineHeight: '1.8' }}>Find me on:
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <a className="home-headingw" href="https://github.com/knhakan" title='Github' style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}><FaGithub size={'20px'} /> GitHub</a>
                        <a className="home-headingw" href="https://medium.com/@kaanonsoftware" title='Medium' style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}><FaMedium size={'20px'} /> Medium</a>
                        <a className="home-headingw" href="https://linkedin.com/in/kaan-hakan/" title='Linkedin' style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}><FaLinkedin size={'20px'} /> Linkedin</a>
                    </div>
                </div>

                <div style={{ lineHeight: '1.8'}}>
                    Or email me at knhakan@gmail.com
                </div>
                <div style={{ height: '200px' }}></div>

            </Container>
        </div>
    )
}

export default Home
